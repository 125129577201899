<template>
  <div class="container home">

    <img class="mb-2"
        alt="ATAG Benelux"
        style="width: 200px;"
        src="../assets/logo-atag.png">
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->

    <div class="mt-3 p-2">
      <h5><strong>Interne test : Apparaat labels scannen</strong></h5>
      <br />

      Met deze app verkennen Adwise en ATAG Benelux wat scannen en herkennen van apparaatlabels kan opleveren. Welke verbeterpotentie heeft dit in het serviceproces en gebruikerservaring?
      <br />
      <br />

      <a href="/#/scan"
          class="btn btn-primary shadow p-2 mt-3 mb-3 rounded"
          style="display: block; background-color: #00447f;">Help mee! Scan een label</a>
      <br />

      Dit is een “proof of concept”. De look-en-feel is in de basis gericht op het intern gebruik en snelle aanpassingen. Het is een testplatform voor de functies en data die dit oplevert. Hoewel onderdelen kunnen terugkomen in een definitieve versie, is de vorm die je nu ziet een "tijdelijk jasje" om te kunnen testen.
      <br />
      <br />

      <strong>Help mee!</strong>
      <br />
      We willen graag zoveel mogelijk (verschillende) labels binnen de ATAG Benelux productgroepen scannen. Dit leidt tot data van verschillende telefoontoestellen, lichtomstandigheden en labels. Zo zijn we het beste voorbereid op hoe een consument of klant in de praktijk apparaatlabels scant.
      <br />
      <br />

      <strong>Hoe werkt het?</strong>
      <br />
      <ul style="text-align: left;"
          class="pl-4">
        <li>We registreren je naam / toestel voor analysedoeleinden</li>
        <li>Scan een label, via: '<a href="/#/scan">Scan</a>'</li>
        <li>Bekijk de resultaten</li>
        <li><em>Corrigeer evt. de resultaten</em></li>
        <li>Je scan wordt opgeslagen in de database</li>
        <li>Je kan de database raadplegen, via: '<a href="/#/history">History</a>'</li>
        <li>Dmv. deze data kunnen we het algoritme verfijnen</li>
      </ul>
      <br />

      <small>&copy;2021 Adwise - Your Digital Brain</small>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name      : 'Home',
  components: {
    // HelloWorld
  }
}
</script>
